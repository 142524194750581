<template>
  <div>
    <!-- 加载更多 -->
    <div class="load_box">
        <van-loading type="spinner" size="24px" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<script>
export default {
  name: "loadmore",

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
  },
};
</script>

<style lang="less" scoped>
    .load_box{
        padding-bottom: 60rem;
        padding-top: 20rem;
        text-align: center;
    }
</style>
