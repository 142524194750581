import request from '@/utils/request'
// 首页
export function mallIndex() {
    return request({
      url: '/v1/mall/index',
      method: 'get'
    })
  }

  // 推荐商品
export function getmallList(params) {
    return request({
      url: '/v1/mall/home/list?page=' + params.page + '&rows=' + params.rows,
      method: 'get',
    })
  }

// 搜索商品
export function searchGood(params) {
    return request({
      url: '/v1/mall/product/list?page=' + params.page + '&rows=' + params.rows + '&cid=' + params.cid + '&keyword=' + params.keyword,
      method: 'get',
    })
}

// 获取分类
export function getCategory() {
    return request({
      url: '/v1/mall/category',
      method: 'get',
    })
}

// 商品详情
export function productInfo(id) {
    return request({
      url: '/v1/mall/product/info?id=' + id,
      method: 'get',
    })
}

// 商品收藏列表
export function collectList(params) {
    return request({
      url: '/v1/mall/collect/list?page=' + params.page + '&rows=' + params.rows,
      method: 'get',
    })
}

// 商品收藏
export function doCollect(params) {
    return request({
        url: '/v1/mall/collect',
        method: 'post',
        data: params
    })
}

// 添加购物车
export function addCart(params) {
    return request({
        url: '/v1/mall/cart',
        method: 'post',
        data: params
    })
}

// 删除购物车
export function removeCart(params) {
    return request({
        url: '/v1/mall/cart/remove',
        method: 'post',
        data: params
    })
}

// 购物车数量
export function cartNumber() {
    return request({
        url: '/v1/mall/cart/number',
        method: 'get'
    })
}

// 购物车列表
export function cartList() {
    return request({
        url: '/v1/mall/cart/list',
        method: 'get'
    })
}

// 猜你喜欢
export function likeList() {
  return request({
      url: '/v1/mall/like/list',
      method: 'get'
  })
}
